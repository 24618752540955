module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6791b68245f7075e33591773db55470f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"id here","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"legal":"/Volumes/RedCraneDrive/GitHub/myserverjapan/myserverjapan-web/src/mdxTheme/MdxLayoutLegal.tsx","default":"/Volumes/RedCraneDrive/GitHub/myserverjapan/myserverjapan-web/src/mdxTheme/MdxLayoutDefault.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/Volumes/RedCraneDrive/GitHub/myserverjapan/myserverjapan-web/node_modules/gatsby-remark-images","id":"00ff09d7-6c1f-5e0d-a686-3b7e79ef3bc2","name":"gatsby-remark-images","version":"6.2.0","modulePath":"/Volumes/RedCraneDrive/GitHub/myserverjapan/myserverjapan-web/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":960},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Volumes/RedCraneDrive/GitHub/myserverjapan/myserverjapan-web","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
